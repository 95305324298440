.loginbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
.countdown1{
    color: #000 !important;
}

.registerbutton2{
    background-color: #CC0100;
    color: #fff;
    border-radius: 50;
    /* border-radius: 10px; */
    font-size: 15px;
    text-align: center;
    padding: 5px !important;
  }
.registerbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
img.demo {
    filter: brightness(120%);
    filter: contrast(120%);
    filter: drop-shadow(10px 10px 10px black);
 }